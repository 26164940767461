import {shallowEqual, useSelector} from 'react-redux';
import cx from 'classnames';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {
  selectAddress,
  selectLocation,
  selectOrganizerEmail,
  selectOrganizerName,
  selectOrganizerPhone,
  selectTitle,
  selectVolunteerMessage,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectBlueprintId} from '~/SignUpSheets/reducers/blueprint/selectors';
import {DetailsOrganizerNote} from './DetailsOrganizerNote';
import {DetailsHeader} from './DetailsHeader';
import {DetailsDate} from './DetailsDate';
import {DetailsLocation} from './DetailsLocation';
import {DetailsOrganizer} from './DetailsOrganizer';
import {usePreviewValue} from './hooks/usePreviewValue';
import {htmlToString} from '~/SignUpSheets/utils/misc';
import './ViewDetails.sass';

export const ViewDetails = () => {
  const title = usePreviewValue(selectTitle, 'Sample Sheet Title');
  const organizerNote = usePreviewValue(selectVolunteerMessage, 'Sample Note', htmlToString);
  const blueprintId = useSelector(selectBlueprintId);
  const isLargishAndUp = useIsLargishAndUp();

  const location = usePreviewValue(selectLocation, 'Location');
  const address = useSelector(selectAddress, shallowEqual);
  const {street, city, state, zip} = address;
  const hasAddress = street || city || state || zip;
  const organizerName = usePreviewValue(selectOrganizerName, 'Organizer');
  const organizerEmail = usePreviewValue(selectOrganizerEmail, 'Organizer email');
  const organizerPhone = usePreviewValue(selectOrganizerPhone, 'Organizer phone');

  const showDivider = location || hasAddress || organizerName || organizerEmail || organizerPhone;

  return (
    <div
      className={cx(
        'page-section',
        'signup-sheet__view-details',
        `signup-sheet__view-details-${blueprintId}`
      )}
    >
      <DetailsHeader title={title} />

      <div className="signup-sheet__view-details__content">
        {isLargishAndUp && (
          <div className="signup-sheet__view-details__content__desktop">
            <DetailsDate />
            <DetailsLocation />
            <DetailsOrganizer />
          </div>
        )}

        {!isLargishAndUp && (
          <>
            <Typography variant="header2" as="h1" className="signup-sheet__view-details__title">
              {title}
            </Typography>

            <DetailsDate />

            {showDivider ? (
              <>
                <Divider
                  marginTop={isLargishAndUp ? '1.25rem' : '1rem'}
                  marginBottom={isLargishAndUp ? '1.25rem' : '1rem'}
                  className="signup-sheet__view-details__content__divider"
                />

                <div className="signup-sheet__view-details__content__location__organizer">
                  <DetailsLocation />
                  <DetailsOrganizer />
                </div>
              </>
            ) : null}
          </>
        )}

        {organizerNote ? (
          <>
            <Divider
              marginTop={isLargishAndUp ? '1.25rem' : '1rem'}
              marginBottom={isLargishAndUp ? '1.25rem' : '1rem'}
              className="signup-sheet__view-details__content__divider"
            />
            <DetailsOrganizerNote organizerNote={organizerNote} />
          </>
        ) : null}
      </div>
    </div>
  );
};
