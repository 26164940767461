import {useSelector} from 'react-redux';
import cx from 'classnames';
import {useMemo} from 'react';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {formatDateShort} from '~/SignUpSheets/utils/misc';
import {selectSheetIsDraft} from '~/SignUpSheets/reducers/sheet/selectors';

export const SplitViewLeftColumn = ({dateSlot, past, allExpired, isToday}) => {
  const hasDateOrTitle = dateSlot.date || dateSlot.title;
  const hasDateAndTitle = dateSlot.date && dateSlot.title;
  const sheetIsDraft = useSelector(selectSheetIsDraft);

  const displayText = useMemo(() => {
    if (!sheetIsDraft) return dateSlot.date ? formatDateShort(dateSlot.date) : dateSlot.title;
    return dateSlot.date ? formatDateShort(dateSlot.date) || '[Sample Date]' : dateSlot.title;
  }, [dateSlot.date, dateSlot.title, sheetIsDraft]);

  return (
    <div className={cx('split-page-section__slot__date-column', {expired: allExpired})}>
      {hasDateOrTitle && (
        <Typography variant="header5" semibold className="split-page-section__slot__date">
          {displayText}
          {past && !allExpired && <Pill expired>past</Pill>}
          {isToday && !past && <Pill>today</Pill>}
        </Typography>
      )}
      {hasDateAndTitle && (
        <Typography
          variant="list2"
          size="large"
          semibold
          className="split-page-section__slot__title"
        >
          {dateSlot.title}
        </Typography>
      )}
      {dateSlot.description && (
        <Typography variant="paragraph3" color="shadow" className="split-page-section__slot__desc">
          {dateSlot.description}
        </Typography>
      )}
    </div>
  );
};

const Pill = ({expired, children}) => (
  <Typography
    as="span"
    className={cx('page-section__status-pill', {expired})}
    variant="label2"
    inline
  >
    {children}
  </Typography>
);
