import {useSelector} from 'react-redux';
import {selectIsPreviewMode, selectSheetIsDraft} from '~/SignUpSheets/reducers/sheet/selectors';

export const usePreviewValue = (selector, previewText, parserFn = null) => {
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const sheetIsDraft = useSelector(selectSheetIsDraft);
  const rawValue = useSelector(selector);
  const value = parserFn ? parserFn(rawValue) : rawValue;
  return isPreviewMode && sheetIsDraft && !value ? `[${previewText}]` : value;
};
