import moment from 'moment-timezone';
import {putAffiliateTagsToUrl} from '~/common/utils/affiliate_tags';
import {eviteVendors} from '~/common/vendors/vendors';
import {parseTimePieces} from '~/SignUpSheets/hooks/useSortTimes';

const now = moment();
export const slotElapsed = (slot) => moment(slot.start_time, 'h:mm a').isBefore(now);
export const signupOptionIsToday = (sop) => moment(sop.date).isSame(now, 'day');
export const signupOptionIsBeforeToday = (sop) => sop.date && moment(sop.date).isBefore(now, 'day');
export const signupOptionIsAfterToday = (sop) => sop.date && moment(sop.date).isAfter(now, 'day');

export const isSpecificSignup = (loopedItem, selectedItem) => {
  if (selectedItem.slot_id) {
    return (
      loopedItem.slot_id === selectedItem.slot_id &&
      loopedItem.signup_option === selectedItem.signup_option
    );
  }
  return (
    loopedItem.signup_option ===
    (selectedItem.signup_option ? selectedItem.signup_option : selectedItem.uuid)
  );
};

export const resizeTextarea = (element) => {
  if (!element) return;
  const elem = element;
  elem.style.height = 'auto';
  elem.style.height = `${elem.scrollHeight + 2}px`;
};

export const getBrandNameAndIcon = (url) => {
  let brandName = '';

  switch (true) {
    case url.toLowerCase().includes('amazon'):
      brandName = 'Amazon';
      break;

    case url.toLowerCase().includes('target'):
      brandName = 'Target';
      break;

    case url.toLowerCase().includes('walmart'):
      brandName = 'Walmart';
      break;

    default:
      break;
  }

  let iconUrl = 'https://g0.evitecdn.com/emails/reinvention/gift_white_blue.png';
  if (brandName) {
    iconUrl = eviteVendors.find((vendor) => vendor.name === brandName)?.icon_url;
  }
  return {brandName, iconUrl};
};

export const normalizeUrl = (url) => {
  if (!url.includes('://')) {
    return `https://${url}`;
  }
  return url;
};

export const addAffiliateTagsToWishlist = (url, blueprintId, reg = true) =>
  putAffiliateTagsToUrl(url, {
    tag: 'sus-tracking-20',
    asc_source: `sus_${blueprintId}|||${reg ? '' : 'no'}reg`,
  });

/**
 * Parse user input and convert to standardized form. This will parse
 * any valid timestring to the format 00:00 AM/PM
 * @param time
 * @returns {null|string}
 */
export const standardizeTimeStr = (time) => {
  // TODO: add 24 hour parsing?
  const match = time.match(/(?<hour>[01]?[0-9])(:?(?<minute>[0-9][0-9]))? ?(?<meridiem>[ap]m)/i);
  if (match) {
    const {hour, minute = '00', meridiem} = match.groups;
    const hourInt = parseInt(hour, 10);
    const minuteInt = parseInt(minute, 10);
    if (hourInt < 13 && hourInt > 0 && minuteInt < 60) {
      return `${hourInt}:${minute} ${meridiem}`.toUpperCase();
    }
  }
  return null;
};

/**
 * Format date in the format Sat, Mar 15, 2025
 * @param date
 * @returns {null|string}
 */
export const formatDateShort = (date) =>
  moment(date).isValid() ? moment(date).format('ddd, ll') : null;

/**
 * Format datetime as a 12-hour time string
 * @param time
 * @returns {null|string}
 */
export const formatTimeShort = (time) =>
  moment(time).isValid() ? moment(time).format('h:mm A') : null;

/**
 * Format date in the format Sat, Mar 15, 2025 5:00 PM
 * @param date
 * @returns {null|string}
 */
export const formatTimestamp = (date) =>
  moment(date).isValid() ? moment(date).format('ddd, ll, h:mm A') : null;

/**
 * Format date range in the format Sat, Mar 15, 2025, 5:00 PM - 10:00 PM
 * @param startDate
 * @param endDate
 * @returns {null|string}
 */
export const formatDateRange = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  if (!start.isValid()) return null;
  if (!end.isValid()) return formatTimestamp(startDate);
  if (end.isAfter(start, 'day')) {
    return `${formatTimestamp(startDate)} - ${formatTimestamp(endDate)}`;
  }
  return `${formatTimestamp(startDate)} - ${formatTimeShort(endDate)}`;
};

/**
 * Format date in the format Saturday, Mar 15, 2025
 * @param date
 * @returns {null|string}
 */
export const formatDateLong = (date) =>
  moment(date).isValid() ? moment(date).format('dddd, ll') : null;

/**
 * Redirects to Google Maps with the specified address.
 * @param {Object} address
 * @returns {string}
 */
export const redirectToGoogleMaps = ({street, city, state, zip}) => {
  const address = [street, city, state, zip].filter((el) => el && el.trim());
  return `https://maps.google.com/maps?q=${address.join('%20')}`;
};

/**
 * Opens an email with all volunteers listed as recipients
 *
 * @param {Object} sheet state object
 */
export const emailSheetVolunteers = ({signups, title}) => {
  const params = new URLSearchParams();
  const uniqueEmails = new Set(signups?.filter((s) => !!s?.email).map((s) => s.email));
  const emails = encodeURIComponent(
    [...uniqueEmails]
      .map((e) => {
        const signup = signups.find((s) => s.email === e);
        return `"${signup.name}" <${signup.email}>`;
      })
      .join(',')
  );
  params.set('subject', title);
  window.location.href = `mailto:${emails}?${params.toString()}`;
};

export const sortOptionsByDate = (a, b) => {
  if (!a?.date && !b?.date) {
    return 0;
  }
  if (!a?.date) {
    return 1;
  }
  if (!b?.date) {
    return -1;
  }
  return a?.date.localeCompare(b?.date);
};

export const isAllDay = (start, end) =>
  start === '' || (start === '12:00 AM' && end === '11:59 PM');

/**
 * Copy time slot toggle value and slot[0] times to newly created date
 * and increment the date
 * @param {Array} arr
 * @returns {Object} the new signup option
 */
export const matchPrevOptionAndIncrementDay = (arr, schema) => {
  const newArr = [...arr];
  const prevDay = newArr[newArr.length - 2];
  const newDay = newArr[newArr.length - 1];
  newDay.all_day = prevDay.all_day;
  if (newDay.all_day !== undefined) {
    newDay.date = moment(prevDay.date).add(1, 'day').format('YYYY-MM-DD');
    newDay.slots[0].start_time = prevDay.slots[0].start_time || schema.placeholder[0];
    newDay.slots[0].end_time = prevDay.slots[0].end_time || schema.placeholder[1];
  }
  return newDay;
};

/**
 * Generates a formatted time 30 minutes later than the provided time
 * @param {string} prevTime
 * @returns {string}
 */
export const getTime30MinsLater = (prevTime) => {
  if (!prevTime) return null;
  const [startHour, startMinutes] = parseTimePieces(prevTime);
  const minEndTS = moment().hour(startHour).minutes(startMinutes);
  const defaultEndTS = moment(minEndTS).add(30, 'minutes');
  return formatTimeShort(defaultEndTS);
};

/**
 * Checks if the provided signup option or slot has a product link specified
 * @param {object} entry the signup option or slot to check
 * @returns {boolean}
 */
export const entryHasProductLink = (entry) => !!entry.product_link?.url;

/**
 * Returns a copy of the provided string converted to Title Case.
 * @param {string} text - the text to convert
 * @returns {string}
 */
export const toTitleCase = (text) =>
  text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

/**
 * Returns a text representation of the provided HTML string
 * @param {*} html - the HTML string to convert
 * @returns {string}
 */
export const htmlToString = (html = '') => {
  const elem = document.createElement('div');
  elem.innerHTML = html;
  return elem.textContent.trim();
};
