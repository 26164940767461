import moment from 'moment-timezone';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {getTimezoneAbbreviation} from '~/SignUpSheets/components/TimezoneSelect/TimezoneSelect';
import {formatDateShort} from '~/SignUpSheets/utils/misc';

const userTimezone = moment.tz.guess();

const isSameDay = (date1, date2, timezone) =>
  moment.tz(date1, timezone).isSame(moment.tz(date2, timezone), 'day');

const dateTimeFormat = (date, timezone, showTimes, abbr = '', includeTzInDate = false) => {
  const format = showTimes ? 'llll' : 'ddd, ll';
  return `${moment.tz(date, timezone).format(format)} ${includeTzInDate ? abbr : ''}`.trim();
};

export const formatDate = (start, end, timezone, showTimes, includeTzInDate = false) => {
  if (!start) return {date: null, abbr: null, timeRange: null};

  const abbr = timezone && timezone !== userTimezone ? getTimezoneAbbreviation(timezone) : '';
  const startFormatted = dateTimeFormat(start, timezone, showTimes);

  const abbrElem = abbr ? (
    <Typography className="paragraph1" color="pebble" as="span">
      {abbr}
    </Typography>
  ) : null;

  const sameDay = isSameDay(start, end, timezone);

  if (!end || sameDay) {
    const dateLine = formatDateShort(moment.tz(start, timezone));
    const startTime = moment.tz(start, timezone).format('LT');
    const endTime = moment.tz(end, timezone).format('LT');

    const timeLine = showTimes ? (
      <Typography variant="paragraph1" color="pebble">
        {`${startTime}${end ? ` - ${endTime}` : ''} ${abbr || ''}`.trim()}
      </Typography>
    ) : null;

    return {
      date: dateLine,
      abbr: timeLine ? null : abbrElem,
      timeRange: timeLine,
    };
  }

  const endFormatted = dateTimeFormat(end, timezone, showTimes, abbr, includeTzInDate);
  return {
    date: (
      <>
        {startFormatted} -<br />
        {endFormatted}
      </>
    ),
    abbr: abbrElem,
    timeRange: null,
  };
};
