import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {PB_CheckmarkCircle as PBCheckmarkCircle} from '~/common/svg/PB_CheckmarkCircle';
import {Typography} from '~/common/_pb_components/atoms/Typography';

import {feedbackSuccess} from '~sass/pb_styleguide/base/_exports.sass';
import './Header.sass';

export const Header = ({title, subtitle, description}) => {
  const isLargishAndUp = useIsLargishAndUp();
  return (
    <div className="signup__header">
      <PBCheckmarkCircle
        ratio={0.5}
        color={feedbackSuccess}
        className="signup__header__checkmark"
      />
      <div className="signup__header__info">
        <Typography variant={isLargishAndUp ? 'header5' : 'paragraph1'} semibold>
          {title}
        </Typography>
        {subtitle && <Typography variant="paragraph3">{subtitle}</Typography>}
        {description && (
          <Typography variant={isLargishAndUp ? 'paragraph3' : 'paragraph4'} color="shadow">
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};
